import {validateEmail} from "../../utils/validation"
import {checkUniqMail, getCategory, getOptionsFormTicket} from "../api/apiAction"
import {lmic} from "../../utils/lmic"
import {validTab} from "../tabs/tabsAction"
import {isDefined} from "../../utils/data"

const countries = require("i18n-iso-countries")
countries.registerLocale(require("i18n-iso-countries/langs/en.json"))
const countriesName = countries.getNames("en")

export const
    CHANGE_PROPERTY_ADD_MEMBER      = "CHANGE_PROPERTY_ADD_MEMBER",
    SET_ERROR_ADD_MEMBER            = "SET_ERROR_ADD_MEMBER",
    SUBMIT_INFORMATION_ADD_MEMBER   = "SUBMIT_INFORMATION_ADD_MEMBER",
    SELECT_TICKET_ADD_MEMBER        = "SELECT_TICKET_ADD_MEMBER",
    SELECT_OPTIONS_ADD_MEMBER       = "SELECT_OPTIONS_ADD_MEMBER",
    CHANGE_INFORMATION_ADD_MEMBER   = "CHANGE_INFORMATION_ADD_MEMBER",
    CHANGE_DOCUMENTS_ADD_MEMBER     = "CHANGE_DOCUMENTS_ADD_MEMBER",
    SUBMIT_DOCUMENTS_ADD_MEMBER     = "SUBMIT_DOCUMENTS_ADD_MEMBER",
    RESET_REGISTRATION_ADD_MEMBER   = "RESET_REGISTRATION_ADD_MEMBER",
    DELETE_FILE_ADD_MEMBER          = "DELETE_FILE_ADD_MEMBER",
    START_EDIT_MEMBER               = "START_EDIT_MEMBER",
    START_ADD_MEMBER                = "START_ADD_MEMBER",
    CHECK_UNIQ_MAIL_LOCAL           = "CHECK_UNIQ_MAIL_LOCAL"


export const onChange = (e, checkbox = false) => dispatch => {
    const {name}    = e.target
    const value     = checkbox ? e.target.checked : e.target.value

    dispatch({
        type: CHANGE_PROPERTY_ADD_MEMBER,
        name: name,
        value: value,
    });
};

export const onChangeByName = (name, value) => dispatch => {
    dispatch({
        type: CHANGE_PROPERTY_ADD_MEMBER,
        name: name,
        value: value,
    });
};

export const setError = (name, error) => dispatch => {
    dispatch({
        type: SET_ERROR_ADD_MEMBER,
        name: name,
        error: error,
    });
};

const determinedCategory = (form, categories, categoryParent) => async dispatch => {
    let category = null

    if(form.professionalCtgSelect.value === "Nurse") {
        category = categories.find(category => category.categories_name === "Nurse" && category.category_parent === categoryParent.idcategories)
    }
    else if(form.professionalCtgSelect.value === "Student") {
        category = categories.find(category => category.categories_name === "Student" && category.category_parent === categoryParent.idcategories)
    }
    else if(lmic.find(country => country === form.registered_country.value) !== undefined) {
        category = categories.find(category => category.categories_name === "LMI Country" && category.category_parent === categoryParent.idcategories)
    }
    else {
        category = categories.find(category => category.idcategories === 5)
    }
    await dispatch(getCategory(category !== undefined && category !== null ? category.idcategories : null, false))
}

export const selectCategory = (categories, value = null) => async dispatch => {
    const category = categories.find(category => category.categories_name === value)

    await dispatch(getCategory(category !== undefined && category !== null ? category.idcategories : null))
}

export const submitInformation = (onEdit, form, categories, categoryParent, members, callback) => async dispatch => {
    const names         = Object.getOwnPropertyNames(form)
    const properties    = Object.values(form)
    let valid           = true

    await properties.forEach(async (property, i) => {
        if(names[i] !== "registered_interest" && names[i] !== "registered_intouch" && names[i] !== "registered_cvg") {
            if(property.required && property.value.length <= 0) {
                dispatch(setError(names[i], "This field is required"))
                valid = false
            }
            else if((names[i] === "registered_mail" || names[i] === "confirmEmail") && !validateEmail(property.value)) {
                dispatch(setError(names[i], "Enter a valid email address"))
                valid = false
            }
            else {
                dispatch(setError(names[i], ""))
            }
        }
    })

    if(onEdit === false) {
        await dispatch(checkUniqMail(form.registered_mail.value))
            .then(res => {
                if(res === false) {
                    dispatch(setError("registered_mail", "An account already exist with this mail"))
                    valid = false
                }
            })
        await dispatch(checkUniqMailLocal(form.registered_mail.value, members))
            .then(res => {
                if(res === false) {
                    dispatch(setError("registered_mail", "An account already exist with this mail"))
                    valid = false
                }
            })
    }

    if(form.registered_mail.value !== form.confirmEmail.value) {
        dispatch(setError("confirmEmail", "Email addresses are not the same"))
        valid = false
    }

    if(valid === true) {        
        if(onEdit === false)
            await dispatch(getCategory(5, false)).then(() => callback())
        else
            await dispatch(getCategory(form.registered_category.value, false)).then(() => callback())
        dispatch(validTab("ticketTab"))
    }

    dispatch({
        type: SUBMIT_INFORMATION_ADD_MEMBER,
        valid: valid
    })
}

export const selectTicket = (ticket) => dispatch => {
    dispatch({
        type: SELECT_TICKET_ADD_MEMBER,
        ticket: ticket,
    });
};

export const selectOptions = (option, add = true) => dispatch => {
    dispatch({
        type: SELECT_OPTIONS_ADD_MEMBER,
        option: option,
        add: add,
    });
};

export const userToForm = (user) => dispatch => {
    Object.keys(user.registered).forEach(property => dispatch(onChangeByName(property, getValue(property, user.registered[property]))));

    dispatch(onChangeByName("professionalCtgSelect", getValue("professionalCtgSelect", user.registered.profession_category)))
    dispatch(onChangeByName("confirmEmail", user.registered.registered_mail))
}

export const getValue = (name, value) => {
    if(name === "registered_under") {
        return value === null ? false : value
    }
    else if(name === "registered_gender") {
        return value === null || value === "" ? "Mr" : value
    }
    else if(name === "gender") {
        return value === null || value === "" ? "Male" : value
    }
    else if(name === "registered_profession_category") {
        return value === null || value === "" ? "Physician" : value
    }
    else
        return value === null ? "" : value
}

export const onChangeInformation = (e, isInterest = true) => dispatch => {
    const {name} = e.target

    dispatch({
        type: CHANGE_INFORMATION_ADD_MEMBER,
        value: name,
        isInterest: isInterest
    });
}


export const onChangeDocuments = (name, value) => dispatch => {
    dispatch({
        type: CHANGE_DOCUMENTS_ADD_MEMBER,
        name: name,
        value: value,
    });
};

export const submitDocuments = (form, callback) => async dispatch => {
    const names         = Object.getOwnPropertyNames(form)
    const properties    = Object.values(form)
    let valid           = true

    await properties.forEach(async (property, i) => {
        if(property.required && property.value.length <= 0) {
            dispatch(setError(names[i], "This field is required"))
            valid = false
        }
        else {
            dispatch(setError(names[i], ""))
        }
    })

    if(valid === true) {
        dispatch(validTab("documentsTab"))
        callback()
    }

    dispatch({
        type: SUBMIT_DOCUMENTS_ADD_MEMBER,
        valid: valid
    })
}

export const userToDocuments = (user) => dispatch => {
    Object.keys(user.registered).forEach(property => dispatch(onChangeDocuments(property, getValue(property, user.registered[property]))));
}

export const resetRegistration = () => dispatch => {
    dispatch({
        type: RESET_REGISTRATION_ADD_MEMBER
    })
}

export const deleteFile = () => dispatch => {
    dispatch({
        type: DELETE_FILE_ADD_MEMBER
    })
}

export const memberToForm = (member) => dispatch => {
    Object.keys(member).forEach(property => dispatch(onChangeByName(property, getValue(property, member[property]))))
    dispatch(onChangeByName("confirmEmail", member.registered_mail))

    dispatch(onChangeByName("countryCode", member.registered_country))
    dispatch(onChangeByName("registered_country", countries.getName(member.registered_country, "en")))
}

export const editMemberToForm = (member) => dispatch => {
    dispatch({
        type: START_EDIT_MEMBER,
        member
    })

    const data = member.registered

    dispatch(editMemberToDocument(data))

    Object.keys(data).forEach(property => dispatch(onChangeByName(property, getValue(property, data[property]))))
    dispatch(onChangeByName("confirmEmail", data.registered_mail))

    dispatch(onChangeByName("countryCode", countries.getAlpha2Code(data.registered_country, 'en')))
    dispatch(onChangeByName("registered_country", data.registered_country))

    const categories    = ["Physician", "Researcher", "Public Health worker", "Student", "Nurse", "Employee", "Other"]

    const nameOfProfessionalInput = isDefined(data.registered_profession_category) ? "registered_profession_category" : "profession_category"
    const valueIsOther  = data.profession_category !== "" ? categories.filter(ctg => ctg !== "Other").indexOf(data[nameOfProfessionalInput]) === -1 : false

    dispatch(onChangeByName("professionalCtgSelect", data[nameOfProfessionalInput]))
    dispatch(onChangeByName("registered_profession_category", data[nameOfProfessionalInput]))

    if(valueIsOther === false) {
        dispatch(onChangeByName("professionalCtgSelect", data[nameOfProfessionalInput]))
    }
    else {
        dispatch(onChangeByName("professionalCtgSelect", "Other"))
    }

    if(isDefined(member.registered_package.packages_price)) {	
        dispatch(selectTicket(member.registered_package))	
    }	
    else {
   
        dispatch(getCategory(data.registered_category, false))
            .then(category => {
                if (member.registered_package !== null) {
                    dispatch(selectTicket(category.package.find(ticket => ticket.idpackages === member.registered_package.idpackage)))
                    dispatch(getOptionsFormTicket(category.idcategories, member.registered_package.idpackage))
                        .then((optionsAPI) => {
                            optionsAPI.forEach(option => { //category.options
                                if(member.registered_option.find(opt => opt.idoptions === option.idoptions))
                                    dispatch(selectOptions(option))
                            })
                        })
                }
            })

    }

    member.registered_option.forEach(option => dispatch(selectOptions(option)))
}

const editMemberToDocument = (member) => dispatch => {
    if(isDefined(member.registered_invit_letter))
        dispatch(onChangeDocuments("registered_invit_letter", member.registered_invit_letter))
    else if(isDefined(member.registered_letter))
        dispatch(onChangeDocuments("registered_invit_letter", member.registered_letter === "1"))
    dispatch(onChangeDocuments("registered_passport_date", member.registered_passport_date))
    dispatch(onChangeDocuments("registered_passport_nato", member.registered_passport_nato))
    dispatch(onChangeDocuments("registered_passportnb", member.registered_passportnb))
    dispatch(onChangeDocuments("registered_birth", member.registered_birth))
}

export const startAddMember = () => dispatch => {
    dispatch({
        type: START_ADD_MEMBER,
    })
}

export const checkUniqMailLocal = (mail, members) => async dispatch => {
    try {
        dispatch({
            type: CHECK_UNIQ_MAIL_LOCAL,
            mail: mail
        })

        let alreadyDoesntExists = true

        await members.forEach(member => {
            if(member.registered.registered_mail === mail)
                alreadyDoesntExists = false
        })

        return alreadyDoesntExists
    } catch (e) {}
}
