import {validateEmail} from "../../utils/validation"
import {
    ERROR,
    getCategory,
    uri,
    userFormatToAPI
} from "../api/apiAction"
import {lmic} from "../../utils/lmic";
import {validTab} from "../tabs/tabsAction"
import {isDefined} from "../../utils/data"

const countries = require("i18n-iso-countries")
countries.registerLocale(require("i18n-iso-countries/langs/en.json"))
const countriesName = countries.getNames("en")

export const
    CHANGE_PROPERTY_COORDINATOR     = "CHANGE_PROPERTY_COORDINATOR",
    SET_ERROR_COORDINATOR           = "SET_ERROR_COORDINATOR",
    SUBMIT_INFORMATION_COORDINATOR  = "SUBMIT_INFORMATION_COORDINATOR",
    SUBMIT_STRIPE_ERROR_COORDINATOR = "SUBMIT_STRIPE_ERROR_COORDINATOR",
    SUBMIT_STRIPE_SUCCESS_COORDINATOR   = "SUBMIT_STRIPE_SUCCESS_COORDINATOR",
    RESET_STRIPE_COORDINATOR        = "RESET_STRIPE_COORDINATOR",
    RESET_REGISTRATION_COORDINATOR  = "RESET_REGISTRATION_COORDINATOR",
    CHECK_UNIQ_MAIL_FAILED_COORDINATOR  = "CHECK_UNIQ_MAIL_FAILED_COORDINATOR",
    CHECK_UNIQ_MAIL_SUCCESS_COORDINATOR = "CHECK_UNIQ_MAIL_SUCCESS_COORDINATOR",

    ADD_MEMBER_COORDINATOR          = "ADD_MEMBER_COORDINATOR",
    GET_AMOUNT_GROUP                = "GET_AMOUNT_GROUP",
    RECORD_GROUP_SUCCESSFUL         = "RECORD_GROUP_SUCCESSFUL",
    RECORD_GROUP_FAILED             = "RECORD_GROUP_FAILED",
    LOGIN_COORDINATOR               = "LOGIN_COORDINATOR",
    LOGIN_COORDINATOR_FAILED        = "LOGIN_COORDINATOR_FAILED",
    REMIND_PASSWORD_COORDINATOR     = "REMIND_PASSWORD_COORDINATOR",
    REMIND_PASSWORD_COORDINATOR_FAILED = "REMIND_PASSWORD_COORDINATOR_FAILED",
    GET_COORDINATOR                 = "GET_COORDINATOR",
    REMOVE_MEMBER                   = "REMOVE_MEMBER",
    START_EDIT_COORDINATOR          = "START_EDIT_COORDINATOR",
    EDIT_MEMBER_COORDINATOR         = "EDIT_MEMBER_COORDINATOR",
    DELETE_FILE                     = "DELETE_FILE"

export const checkUniqMail = (email) => async dispatch => {
    try {
        const url = uri + 'checkuniqmailgroup';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "group_mail": email,
            })
        });

        if (res.status === 200) {
            dispatch({
                type: CHECK_UNIQ_MAIL_FAILED_COORDINATOR,
            });

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: CHECK_UNIQ_MAIL_SUCCESS_COORDINATOR,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const onChange = (e, checkbox = false) => dispatch => {
    const {name}    = e.target
    const value     = checkbox ? e.target.checked : e.target.value

    dispatch({
        type: CHANGE_PROPERTY_COORDINATOR,
        name: name,
        value: value,
    });
};

export const onChangeByName = (name, value) => dispatch => {
    dispatch({
        type: CHANGE_PROPERTY_COORDINATOR,
        name: name,
        value: value,
    });
};

export const setError = (name, error) => dispatch => {
    dispatch({
        type: SET_ERROR_COORDINATOR,
        name: name,
        error: error,
    });
};

export const submitInformation = (onEdit, form, callback) => async dispatch => {
    const names         = Object.getOwnPropertyNames(form)
    const properties    = Object.values(form)
    let valid           = true

    await properties.forEach(async (property, i) => {
        if(names[i] !== "group_cgv") {
            if(property.required && property.value.length <= 0) {
                dispatch(setError(names[i], "This field is required"))
                valid = false
            }
            else if((names[i] === "group_mail" || names[i] === "confirmEmail") && !validateEmail(property.value)) {
                dispatch(setError(names[i], "Enter a valid email address"))
                valid = false
            }
            else {
                dispatch(setError(names[i], ""))
            }
        }
    })

    if(onEdit === false) {
        await dispatch(checkUniqMail(form.group_mail.value))
            .then(res => {
                if(res === false) {
                    dispatch(setError("group_mail", "An account already exist with this mail"))
                    valid = false
                }
            })
    }

    if(form.group_mail.value !== form.confirmEmail.value) {
        dispatch(setError("confirmEmail", "Email addresses are not the same"))
        valid = false
    }

    if(valid === true) {
        dispatch(validTab("membersTab"))
        callback()
    }

    dispatch({
        type: SUBMIT_INFORMATION_COORDINATOR,
        valid: valid
    })
}

export const userToForm = (user) => dispatch => {
    Object.keys(user.registered).forEach(property => dispatch(onChangeByName(property, getValue(property, user.registered[property]))));
    dispatch(onChangeByName("confirmEmail", user.registered.registered_mail))
}

export const getValue = (name, value) => {
    if(name === "registered_under") {
        return value === null ? false : value
    }
    else if(name === "registered_gender") {
        return value === null || value === "" ? "Mr" : value
    }
    else if(name === "gender") {
        return value === null || value === "" ? "Male" : value
    }
    else if(name === "registered_profession_category") {
        return value === null || value === "" ? "Physician" : value
    }
    else
        return value === null ? "" : value
}
export const submitStripe = (result) => dispatch => {
    if(result.error) {
        dispatch({
            type: SUBMIT_STRIPE_ERROR_COORDINATOR,
            error: result.error.message,
        })
    }
    else {
        if(result.paymentIntent.status === 'succeeded')
            dispatch({
                type: SUBMIT_STRIPE_SUCCESS_COORDINATOR,
            })
    }
}

export const resetStripe = () => dispatch => {
    dispatch({
        type: RESET_STRIPE_COORDINATOR
    })
}

export const resetRegistration = () => dispatch => {
    dispatch({
        type: RESET_REGISTRATION_COORDINATOR
    })
}

export const memberToForm = (member) => dispatch => {
    Object.keys(member).forEach(property => dispatch(onChangeByName(property, getValue(property, member[property]))))
    dispatch(onChangeByName("confirmEmail", member.registered_mail))

    dispatch(onChangeByName("countryCode", member.registered_country))
    dispatch(onChangeByName("group_society_country", countries.getName(member.registered_country, "en")))
}

export const addMember = (member) => async dispatch => {
    let amount = 0
    await dispatch(getAmount(member.category.idcategories, member.ticket.idpackages, member.options.map(option => { return {idoptions: option.idoptions}}))).then(res => amount = res)
    const body = {
        ...userFormatToAPI(member),
        amount: amount
    }

    try {
        if(member.onEdit === false)
            dispatch({
                type: ADD_MEMBER_COORDINATOR,
                member: body,
            })
        else
            dispatch({
                type: EDIT_MEMBER_COORDINATOR,
                member: body,
            })

        return true
        
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const getAmount = (category, ticket, options) => async dispatch => {
    try {
        const url = uri + 'singleamountorder';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "idcategory": category,
                "idpackage": ticket,
                "options": options
            })
        });

        if (res.status === 200) {
            const data  = await res.json();

            dispatch({
                type: GET_AMOUNT_GROUP,
                amount: data
            })

            return data
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        })
    }
}

export const groupFormatToAPI = (data, payment = 0) => {
    const information   = data.information
    let body            = {group: {}, group_registered: []}

    Object.keys(information).forEach(property => {
        if(property !== "single" && property !== "unionMember" && property !== "countryCode" && property !== "gender" && property !== "confirmEmail" && property !== "professionalCtgSelect" && property !== "profession_category") {
            body.group[property] = information[property].value !== undefined ? information[property].value : null
        }
    })

    if(data.category !== null)
        body.group.group_category = data.category.idcategories

    body.payment = payment

    body.group_registered = data.members.map(member => {
        return member
    })

    return body
}

export const createGroup = (data, payment = 0) => async dispatch => {
    const body = groupFormatToAPI(data, payment)
    console.log(body)

    try {
        const url = uri + 'group/' + (isDefined(data.information.idgroup) ? data.information.idgroup.value : "");
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        if (res.status === 200) {
            const data = await res.json();
            dispatch({
                type: RECORD_GROUP_SUCCESSFUL,
                data: data,
            });

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: RECORD_GROUP_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const loginCoordinator = (email, password) => async dispatch => {
    try {
        const url = uri + 'grouplogin';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "group_mail": email,
                "group_password": password
            })
        });

        if (res.status === 200) {
            const data  = await res.json();

            dispatch({
                type: LOGIN_COORDINATOR,
                id: data.idgroup
            });

            dispatch(getCoordinator(data.idgroup))
            dispatch(startEdit())

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: LOGIN_COORDINATOR_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const getCoordinator = (id) => async dispatch => {
    try {
        const res = await fetch(uri + "group/" + id)

        if (res.status >= 200 && res.status <= 201) {
            const data = await res.json();
            dispatch({
                type: GET_COORDINATOR,
                coordinator: data,
            })

            dispatch(getCategory(data.group.group_category, false))

            Object.keys(data.group).forEach(property => {
                if(property !== "group_category" && property !== "group_stripe_id") {
                    dispatch(onChangeByName(property, getValue(property, data.group[property])))
                }
            });

            dispatch(onChangeByName("confirmEmail", data.group.group_mail))
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
}


export const remindPasswordCoordinator = (email) => async dispatch => {
    try {
        const url = uri + 'remindpasswordgroup';
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "group_mail": email,
            })
        });

        if (res.status === 200) {
            dispatch({
                type: REMIND_PASSWORD_COORDINATOR,
            });

            return true
        }
        else {
            const data = await res.json();
            dispatch({
                type: REMIND_PASSWORD_COORDINATOR_FAILED,
                error: data
            });
            return false
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        });
        return false
    }
}

export const removeMember = (member) => async dispatch => {
    try {
        dispatch({
            type: REMOVE_MEMBER,
            member: member,
        })
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        })
    }
}

export const deleteFile = () => dispatch => {
    dispatch({
        type: DELETE_FILE
    })
}

export const startEdit = () => dispatch => {
    try {
        dispatch({
            type: START_EDIT_COORDINATOR,
        })
    } catch (e) {
        dispatch({
            type: ERROR,
            error: e
        })
    }
}
