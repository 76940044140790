// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cfp-js": () => import("./../../src/pages/cfp.js" /* webpackChunkName: "component---src-pages-cfp-js" */),
  "component---src-pages-cfp-medecin-js": () => import("./../../src/pages/cfp_medecin.js" /* webpackChunkName: "component---src-pages-cfp-medecin-js" */),
  "component---src-pages-confirmation-free-record-js": () => import("./../../src/pages/confirmation-free-record.js" /* webpackChunkName: "component---src-pages-confirmation-free-record-js" */),
  "component---src-pages-confirmation-member-js": () => import("./../../src/pages/confirmation_member.js" /* webpackChunkName: "component---src-pages-confirmation-member-js" */),
  "component---src-pages-confirmation-payment-group-js": () => import("./../../src/pages/confirmation-payment-group.js" /* webpackChunkName: "component---src-pages-confirmation-payment-group-js" */),
  "component---src-pages-confirmation-payment-js": () => import("./../../src/pages/confirmation-payment.js" /* webpackChunkName: "component---src-pages-confirmation-payment-js" */),
  "component---src-pages-confirmation-record-js": () => import("./../../src/pages/confirmation-record.js" /* webpackChunkName: "component---src-pages-confirmation-record-js" */),
  "component---src-pages-error-payment-js": () => import("./../../src/pages/error-payment.js" /* webpackChunkName: "component---src-pages-error-payment-js" */),
  "component---src-pages-group-js": () => import("./../../src/pages/group.js" /* webpackChunkName: "component---src-pages-group-js" */),
  "component---src-pages-group-select-js": () => import("./../../src/pages/group_select.js" /* webpackChunkName: "component---src-pages-group-select-js" */),
  "component---src-pages-group-wait-js": () => import("./../../src/pages/group_wait.js" /* webpackChunkName: "component---src-pages-group-wait-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-js": () => import("./../../src/pages/individual.js" /* webpackChunkName: "component---src-pages-individual-js" */),
  "component---src-pages-login-js": () => import("./../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

